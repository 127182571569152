module.exports = [{
      plugin: require('../plugins/splitio-plugin/gatsby-browser.tsx'),
      options: {"plugins":[],"sdk":{"authorizationKey":"i6hpc9lapop5qhhfan007kre95ca885fe836"},"pathsToMask":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Farewill","start_url":"/","background_color":"#FFDF4E","theme_color":"#FFDF4E","display":"minimal-ui","icon":"static/icons/circle-lockup-logo-icon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"27ec4242ccc074acf0e64418228f0928"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/build_ba48287b/src/PageProvider.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/lib/gatsby","projectRoot":"/tmp/build_ba48287b"},
    }]
